import {
  CarOutlined,
  DownOutlined,
  LogoutOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  QuestionOutlined,
  SettingOutlined,
  TableOutlined,
  ToolOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Col, Dropdown, Layout, Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { get } from "../api";
import { User } from "../types";
import Omnisearch from "./Omnisearch";

const { Header: AntHeader } = Layout;

async function logout() {
  await get("auth/logout");
  window.location.reload();
}

const Header = (props: { user: User; accountId?: any }) => {
  const { user, accountId } = props;
  let baseURL = `https://static.inmatics.io`;

  if (!user) {
    return null;
  }

  return (
    <AntHeader
      className={"user-menu"}
      style={{
        position: "fixed",
        zIndex: 3,
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Col span={18}>
        <div style={{ display: "flex" }}>
          <a href={"/"} style={{ display: "flex" }}>
            <img
              className="logo"
              src={`${baseURL}/logos/logo_white.svg`}
              alt=""
              width="150"
            />
          </a>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: 17 }}
          >
            {accountId && (
              <div>
                <Omnisearch accountId={accountId} />
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Link to={"/" + accountId + "/vehicles/create"}>
                          <Button type={"link"}>
                            <CarOutlined style={{ color: "#757575" }} />
                            Vehículo
                          </Button>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to={"/" + accountId + "/services/create"}>
                          <Button type={"link"}>
                            <ToolOutlined style={{ color: "#757575" }} />
                            Servicio
                          </Button>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to={"/" + accountId + "/contacts/create"}>
                          <Button type={"link"}>
                            <UserAddOutlined style={{ color: "#757575" }} />
                            Conductor
                          </Button>
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    className={"ml-2"}
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                  />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </Col>

      <Col className="user" span={6}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Link to={"/" + accountId + "/bulk-insert"}>
                  <Button type={"link"}>
                    <TableOutlined />
                    Carga masiva
                  </Button>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to={"/me"}>
                  <Button type={"link"}>
                    <ProfileOutlined />
                    Mis datos
                  </Button>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to={`/${accountId}/preferencias`}>
                  <Button type={"link"}>
                    <SettingOutlined />
                    Configuración cuenta
                  </Button>
                </Link>
              </Menu.Item>
              {user.platform_admin && (
                <Menu.Item>
                  <Link to={`/admin`}>
                    <Button type={"link"}>
                      <SettingOutlined /># Admin
                    </Button>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item>
                <Link target="_blank" to={`/ayuda`}>
                  <Button type={"link"}>
                    <QuestionOutlined />
                    Ayuda
                  </Button>
                </Link>
              </Menu.Item>
              <Menu.Item onClick={logout}>
                <Button type={"link"}>
                  <LogoutOutlined />
                  Terminar sesión
                </Button>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <div>
            <Avatar>
              {user.first_name && user.first_name[0]?.toUpperCase()}
              {user.last_name && user.last_name[0].toUpperCase()}
            </Avatar>
            <Button ghost={true} icon={<DownOutlined />}>
              {user.platform_admin && "#"}{" "}
              {(user.first_name || "") + " " + (user.last_name || "")}
            </Button>
          </div>
        </Dropdown>
      </Col>
    </AntHeader>
  );
};
export default Header;
