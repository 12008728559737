import { Card, Col, Row, Statistic } from "antd";
import React from "react";

import { useDataApi } from "../../../hooks/useDataApi";
import { formatMoney } from "../../../utils/format";

export default function Entity({ vehicleId }) {
  const [state] = useDataApi(`transactions/summary?vehicleId=${vehicleId}`);

  const { data } = state;

  return (
    <Card title={"Cobranza"}>
      <Row>
        <Col>
          {" "}
          <Statistic
            title="Últimos 7 días"
            value={data["seven"]}
            formatter={formatMoney}
          />
        </Col>
        <Col>
          {" "}
          <Statistic
            title="Últimos 30 días"
            value={data["thirty"]}
            formatter={formatMoney}
          />
        </Col>
        <Col>
          {" "}
          <Statistic
            title="Ulitmos 90 días"
            value={data["ninety"]}
            formatter={formatMoney}
          />
        </Col>
        <Col>
          {" "}
          <Statistic
            title="Total"
            value={data["total"]}
            formatter={formatMoney}
          />
        </Col>
      </Row>
    </Card>
  );
}
