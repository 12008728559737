import { Card } from "antd";
import React from "react";

import { Vehicle } from "../../../types";

const fields = [
  { label: "Patente", name: "license_plate" },
  { label: "Año", name: "year" },
  { label: "Chasis", name: "vin" },
  { label: "Marca", name: "brand" },
  { label: "Modelo", name: "model" },
  { label: "Nombre", name: "name" },
  { label: "Titular", name: "owner" },
  { label: "CUIT titular", name: "owner_cuit" },
  { label: "Licencia taxi", name: "taxiLicense" },
];

export default function VehicleDetails({ vehicle }: { vehicle: Vehicle }) {
  if (!vehicle) {
    return null;
  }

  return (
    <Card title={"Detalles"} style={{ minHeight: 320 }}>
      <table className="service-view-table">
        <tbody>
          {fields.map((x, idx) => (
            <tr key={idx}>
              <td align={"right"}>
                <b>{x.label}</b>
              </td>
              {/*// @ts-ignore*/}
              <td>{vehicle[x.name] || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}
