import qs from "qs";
import {Filters, QueryState} from "../types";

export function getQueryFromURL(s: string): QueryState {
    const parsed = qs.parse(s, { ignoreQueryPrefix: true }) as {
        filters: { [key: string]: string },
        sort?: string,
        page?: string
    };

    const filters: Filters = {};

    for (const key in parsed.filters) {
        if (Object.prototype.hasOwnProperty.call(parsed.filters, key)) {
            const parts = key.split('_');
            const operand = parts.pop()!; // Take the last part as the operand
            const filterName = parts.join('_'); // Join the rest as the filter name
            const value = parsed.filters[key];

            if (value) { // Skip filters with no value
                filters[filterName] = { value: value, operand: operand };
            }
        }
    }

    return {
        filters: filters,
        sort: parsed.sort,
        page: Math.max(1, parseInt(parsed.page || '1', 10))
    };
}