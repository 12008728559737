import { LineOutlined } from "@ant-design/icons";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/es_ES";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Admin from "./Admin/Admin";
import { AuthContext } from "./hooks/useAuth";
import Account from "./routes/Account/Account";
import CreateAccount from "./routes/Account/CreateAccount";
import Help from "./routes/Help/Help";
import Me from "./routes/User/Me";
import { useSimpleSWRFetch } from "./utils/rest";
import DisabledAccount from "./routes/Account/DisabledAccount";

let createAccount = "/create-account";

function App() {
  const { data, isValidating } = useSimpleSWRFetch("auth/user", {
    revalidateOnFocus: false,
  });
  let redirectURL;

  if (!data || isValidating) {
    return null;
  }

  const pathname = window.location.pathname;

  const roles = data.roles || [];

  //User has no role in any account
  if (roles.length === 0 && pathname !== createAccount) {
    redirectURL = createAccount;
  }

  //User has a role in any account, so should be redirected to that account
  if (
    (roles.length === 1 && pathname === createAccount) ||
    (roles.length === 1 && pathname === "/")
  ) {
    redirectURL = "/" + roles[0].account_id;
  }

  if (redirectURL && pathname !== "/me") {
    window.location.replace(redirectURL);
  }

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <LineOutlined style={{ fontSize: 30 }} />
      <p style={{ fontSize: 16, marginTop: 25 }}>
        No encontramos datos para esa consulta.
      </p>
    </div>
  );

    let accountIsDisabled = roles[0].account?.status === "disabled";
    if (accountIsDisabled) {

        return (
            <AuthContext.Provider value={data}>
                <BrowserRouter>
                    <DisabledAccount/>
                </BrowserRouter>
            </AuthContext.Provider>)
    }

  return (
    <AuthContext.Provider value={data}>
      <ConfigProvider renderEmpty={customizeRenderEmpty} locale={locale}>
        <BrowserRouter>
          <Switch>
            <Route path={createAccount} component={CreateAccount} />
            <Route path={"/me"} component={Me} />
            <Route path={"/admin"} component={Admin} />
            <Route path={"/ayuda"} component={Help} />
            <Route path={"/:accountId"} component={Account} />
          </Switch>
        </BrowserRouter>
      </ConfigProvider>
    </AuthContext.Provider>
  );
}

export default App;
