import React, { useEffect } from "react";
import Helmet from "react-helmet";

import { useDataApi } from "../../hooks/useDataApi";
import DashboardComments from "./DashboardComments";
import DashboardLasLocations from "./DashboardLasLocations";
import DashboardTransactions from "./DashboardTransactions";
import DashboardVehicleIgnition from "./DashboardVehicleIgnition";
import DashboardVehicleStatus from "./DashboardVehicleStatus";
import DashboardVehiclesAssigned from "./DashboardVehiclesAssigned";

export default function Dashboard({ match, history }) {
  const { accountId } = match.params;
  const [state] = useDataApi("reports/vehicle-count");

  useEffect(() => {
    if (state.data[0] && state.data[0].count === 0) {
      history.push(`/${accountId}/vehicles`);
    }
  }, [state.data, accountId, history]);

  const allowedAccountIds = new Set(["1", "55", "56", "57"]);
  const shouldShowCobranzas = allowedAccountIds.has(accountId);
  return (
    <div style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>Centro de control | Inmatics</title>
      </Helmet>

      <div className="tile-wrapper p-1">
        <div>
          <DashboardVehicleStatus
            accountId={accountId}
            style={{ gridRow: "1/6", gridColumn: "2/3" }}
          />
        </div>
        <div style={{ gridRow: "1/6", gridColumn: "3/3" }}>
          <DashboardVehiclesAssigned />

          <DashboardVehicleIgnition accountId={accountId} />
        </div>

        <div style={{ gridRow: "1/6", gridColumn: "2/3" }}>
          <DashboardComments accountId={accountId} />
        </div>
        {/*Esto es solo para la cuenda de VADE SRL, cuenta de taxis que usa cobranzas*/}
        {shouldShowCobranzas && (
          <DashboardTransactions title={"Cobranzas"} category={"collection"} />
        )}
        <DashboardLasLocations />
      </div>
    </div>
  );
}
